import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";
import * as React from "react";

import { Layout } from "../components/Layout";
import { ContactForm } from "../elements/ContactForm/ContactForm";

const Contact = ({ data: { wpPage } }) => {
  return (
    <Layout invertHeader>
      <Seo post={wpPage} />
      <ContactForm activeStep="first" />
    </Layout>
  );
};

export default Contact;

export const pageContactQuery = graphql`
  query {
    wpPage(slug: { eq: "contact" }) {
      nodeType
      title
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`;
